// sass-lint:disable no-important property-sort-order
@import '../../../assets/stylesheets/base/colors';

.error-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-container {
  width: 680px;
  height: 424px;
  flex-shrink: 0;
  border: 1px solid $gray3;
  margin-top: 80px;
  background-color: $white;
}

.logo-container {
  display: flex;
  height: 120px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.error-body {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
  padding-top: 54px;
}

.error-text {
  font-size: 14px;
  font-weight: 600;
  color: $gray1;
}

.error-instruction {
  font-size: 14px;
  font-weight: 400;
  color: $gray1;
}

.error-msg {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: $ragRed120;
  text-align: center;
}

.error-desc {
  padding-left: 40px;
  border-left: 1px $gray4 solid;
}

.error-link {
  text-decoration: underline !important;
}
