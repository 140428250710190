// sass-lint:disable no-important
@import '../../assets/stylesheets/base/colors';
@import '../../assets/stylesheets/base/typography';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.active-saved-filters-text {
  color: $takedaRed;
}

.no-saved-filters {
  color: $gray1;
}

.saved-filters {
  color: $gray2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
  max-height: 25vh;
  overflow: auto;

  .filter-name {
    @include componentStyle('tag');
  }

  .filter-labels {
    align-items: center;
    gap: 12px;

    .filter-label {
      p {
        border: 1px solid $gray3;
        border-radius: 52px;
        color: $gray2;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding: 5px 12px;
      }
    }

    .divider {
      border-left: 1px solid $gray4;
      height: 28px;
    }

    .expand-chip {
      align-items: center;
      display: flex;
      gap: 12px;

      img {
        cursor: pointer;
        height: 24px;

        &.expanded {
          transform: rotate(180deg);
        }
      }

      span {
        color: $gray2;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .saved-filter-chip {
    background-color: $white;
    color: $gray2;
  }

  .my-filter-actions {
    color: $takedaRed;
    gap: 20px;
    padding-left: 0;

    .divider {
      border-left: 1px solid $gray4;
      height: 26px;
    }

    .action-container {
      gap: 8px;

      &.edit-button {
        img {
          height: 1rem;
        }
      }
    }
  }

  .action-btn-text {
    @include componentStyle('h4');
  }

  .invalid-filter {
    .p-chip-text {
      .filter-label {
        text-decoration: line-through;
      }
    }
  }
}
