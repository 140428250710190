// sass-lint:disable no-important
@import '../../assets/stylesheets/base/colors';

.list-table {
  background-color: $white !important;
}

.error-message-site {
  color: $ragRed120;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: 20px;
  line-height: 14px;
  margin-bottom: -9px;
  margin-top: 2px;
}

.is-visible-site {
  visibility: hidden;
}

.header-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50.5625rem;

  p {
    color: $gray1;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }

  .sub-mesage {
    font-weight: 400;
  }
}

.sitehealth-count {
  font-size: 17.6px;
}

.playlist-item {
  margin-bottom: 10px;
  width: 100%;

  .playlist-item-content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .delete-icon {
      color: $ragRed;
    }
  }
}

.playlist-name {
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
}

.underline {
  border-bottom: 1px solid $black;
  margin-top: 10px;
}

.my-filters {
  flex-shrink: 0;
  height: 15.75rem;
}

.loader-container {
  align-items: center;
  display: flex;
  height: 70%;
  justify-content: center;
  overflow: hidden;
  /* Ensure no scrollbars */
  padding-top: 25px;
  text-align: center;
  width: 100%;
}

.site-list-name {
  color: $takedaRed;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 17.6px;
  font-style: normal;
  font-weight: 400;
  height: 2.1875rem;
  justify-content: center;
  margin-top: 10px;
  text-decoration-line: underline;
  width: 18.375rem;
}

.number-sites {
  color: $gray1;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-site-list {
  align-items: center;
  display: inline-flex;
  gap: 0.5rem;

  .edit-list-name {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;
    width: 14rem;
  }
}
