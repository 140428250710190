@import '../assets/stylesheets/base/colors';

.gray-message-text {
  color: $gray3;
}

.universal-error,
.universal-loader {
  img {
    width: 1.7rem;
  }
}

.universal-loader-container {
  align-items: center;
  height: 100%;
}
