@import '../../assets/stylesheets/base/colors';

.main-site {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;

  .main-edit-button {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 1rem;
  }

  .edit-button {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    .active-edit {
      height: 1rem;
      width: 1rem;
    }

    p {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }

    .enabled {
      color: $takedaRed;

      &:hover {
        color: $azukiMaroon;
      }
    }

    &:hover {
      color: $azukiMaroon;
    }

    .disabled {
      color: $gray8;
    }
  }

  .trash {
    align-items: center;
    background: $white;
    cursor: pointer;
    display: flex;
    gap: 0.625rem;
  }
}
