// sass-lint:disable no-important
// sass-lint:disable max-file-line-count
// sass-lint:disable no-attribute-selectors
// sass-lint:disable no-qualifying-elements
// sass-lint:disable no-misspelled-properties

@import '../../../assets/stylesheets/base/colors';

.search-box {
  align-items: center;
  background: $white;
  border: 1px solid $gray2;
  display: flex;
  gap: 0.5rem;
  height: 38px;
  padding: 8px 12px;

  .search-icon {
    height: 1rem;
    width: 1rem;
  }

  .search-input {
    border: 0 !important;
    color: $gray1;

    &::placeholder {
      color: $gray3; // Default placeholder color
      transition: color 0.3s ease;

      &::placeholder {
        color: lighten($gray3, 20%); // Lighter color when input is focused
      }
    }

    // Add browser prefixes for better support
    &:-ms-input-placeholder {
      color: $gray3;
    }

    &::-ms-input-placeholder {
      color: $gray3;
    }

    &::-webkit-input-placeholder {
      color: $gray3;
      transition: color 0.3s ease;
    }

    &:focus {
      ::-webkit-input-placeholder {
        color: lighten($gray3, 20%);
      }
    }
  }

  .search-submit {
    height: 1rem;
    width: 1rem;
  }

  &:has(.search-input:focus) {
    background: $white;
    border: 1px solid $gray2;
    box-shadow: 0 2px 4px 0 $chatWindowShadowColor;
  }
}

.filter-panel-icon {
  background: transparent;
  border: 0;
  cursor: pointer;

  img {
    height: 14px;
    width: 12px;

    &.reset-icon {
      width: 15px;
    }

    &.view-icon {
      height: 17px;
      width: 17px;
    }
  }
}

.save-filter {
  &.disabled {
    cursor: auto;

    p {
      color: $gray3;
    }

    .save-icon {
      color: $gray3;
    }
  }
}

.filter-main {
  column-gap: 34px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 24px;

  .group-filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 277px;

    .filter-container {
      min-height: unset;

      .filter-body {
        height: 97px;
      }
    }
  }

  .filter-container {
    min-height: 277px;
    width: 224px;

    .filter-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .filter-title {
        color: $gray1;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%; /* 21px */
      }

      .filter-selected {
        display: flex;
        flex-direction: row;
        gap: 10px;

        p {
          &:nth-of-type(1) {
            color: $gray3;
            font-size: 12px;
          }

          &:nth-of-type(2) {
            border-bottom: 1px solid $selectedFilterBottomBorder;
            text-align: center;
            width: 15px;
          }
        }
      }
    }

    .filter-body {
      border: 1px solid $gray4;
      display: flex;
      flex-direction: column;
      height: 238px;
      padding-right: 4px;
      width: 100%;

      input {
        &[type='radio'] {
          accent-color: $takedaRed;
          width: 1rem;
        }
      }

      .divider {
        border-top: 1px solid $gray4;
        height: 1px;
        margin-bottom: 6px;
        margin-left: 12px;
        margin-right: 12px;
      }

      .search-container {
        border: 1px solid $gray2;
        display: flex;
        gap: 4px;
        height: 24px;
        margin: 11px 12px;
        padding: 2px 8px;

        img {
          width: 12px;
        }

        .search-input {
          border: 0 !important;
          font-size: 12px;
          height: 100%;
        }
      }
    }
  }

  .radio-filters-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 224px;

    .filter-container {
      border-bottom: 1px solid $gray4;
      min-height: unset;

      &:nth-last-child(1) {
        border-bottom: 0;
      }

      .radio-options {
        display: flex;
        gap: 10;
        padding-bottom: 24px;
      }
    }
  }
}

.checkbox-option {
  display: flex;
  flex-direction: row;
  gap: 10px;

  &:hover {
    cursor: pointer;
  }
}

.disable-cursor {
  cursor: default !important;
}

.filter-search {
  border: 1px solid $gray7;
  font-size: 15px;
  font-weight: 400;
  padding-right: 4rem;
  text-align: left;

  &.active {
    border-bottom: 3px solid $gray7;
    margin-bottom: -1px;
  }

  &::placeholder {
    font-style: italic;
  }
}

.radio-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.check-box {
  margin-left: 12px;
  margin-top: 12px;

  input {
    &[type='radio'] {
      margin: 0;
    }
  }
}

.p-inputField-text {
  height: 25px !important;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

.filter-options {
  overflow-y: scroll;
}

.scroll-container {
  overflow-y: auto; /* Enable vertical scroll */
  padding: 10px;
  width: 200px;

  &::-webkit-scrollbar {
    background: $gray4;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $white;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 35%,
      /* Space above the first line */ $gray2 35%,
      $gray2 39%,
      /* First line (increased height to 4%) */ transparent 39%,
      transparent 50%,
      /* Space between lines */ $gray2 50%,
      $gray2 54%,
      /* Second line (increased height to 4%) */ transparent 54%,
      transparent 65%,
      /* Space between lines */ $gray2 65%,
      $gray2 69%,
      /* Third line (increased height to 4%) */ transparent 69%,
      transparent 80%,
      /* Space between lines */ $gray2 80%,
      $gray2 84%,
      /* Fourth line (increased height to 4%) */ transparent 84%,
      transparent 100% /* Space after the fourth line */
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 30px;
    border: 2px solid $gray4;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-track {
    background: $gray4; /* Gray scrollbar track */
  }
}

.greyed-out {
  border-color: $gray11;
  color: $gray3 !important;
  font-weight: bold;
}

.p-checkbox {
  .p-checkbox-box {
    border-color: $gray2 !important;
    border-style: solid;
    border-width: 0.5px;
    cursor: pointer;
    font-size: 10px;
    min-height: 1rem !important;
    min-width: 1rem !important;

    &.p-disabled {
      background-color: $gray5 !important;
      border-color: $gray4 !important;
      border-style: solid;
      border-width: 0.5px;
      cursor: pointer;
      font-size: 10px;
      min-height: 1rem !important;
      min-width: 1rem !important;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    .p-checkbox-label {
      color: $gray10;
      cursor: not-allowed;
    }

    .p-checkbox-box {
      background-color: $gray11;
      border-color: $gray3 !important;

      .p-checkbox-checkmark {
        background-color: $gray11;
      }
    }
  }
}

.checkbox-label {
  color: $gray2;
  font-size: 12px;
  font-weight: 400;
}

.radio-button-container {
  align-items: flex-end;
  display: flex;
  gap: 8px;

  &.disabled {
    color: $gray3 !important;
    cursor: default !important;

    label {
      color: $gray3 !important;
      cursor: default;
    }

    input {
      accent-color: $gray5 !important;
      color: $gray5;
      cursor: default;
    }
  }

  label {
    color: $gray2;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  input {
    &[type='radio'] {
      accent-color: $takedaRed;
      height: 1rem;
      margin: 0;
      width: 1rem;
    }
  }

  input,
  label {
    cursor: pointer;
  }
}
